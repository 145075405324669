import { pokemonApi } from '../../RTKService';
import { getAuthURL } from '../../../getURLHelpers/getURLHelpers';
import { RequestOptionsType } from '../../../request';
import {
  Login,
  LoginRequest,
  PatchedChangePasswordRequest,
  Registration,
  RegistrationRequest,
  User,
} from 'ApiTypes';
import { WSInstance } from 'services/instance';

export const authApi = pokemonApi.injectEndpoints({
  endpoints: (build) => ({
    activation: build.query<
      'User was activated',
      { token: string | null; customOptions?: RequestOptionsType }
    >({
      query: ({ token, customOptions }) => ({
        url: getAuthURL(`activate/${token}`),
        method: 'get',
        customOptions: customOptions,
      }),
    }),
    getUser: build.query<User, { customOptions?: RequestOptionsType } | void>({
      query: (params) => ({
        url: getAuthURL('user'),
        method: 'get',
        customOptions: params?.customOptions,
      }),
      async onCacheEntryAdded(
        _,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        let ws;
        try {
          ws = new WSInstance('/auth/user/');
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            updateCachedData(() => JSON.parse(event.data));
          };

          ws.addEventListener('message', listener);
        } catch (error) {
          console.error(error);
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws && ws.close();
      },
      providesTags: ['login', 'patchUser'],
    }),
    register: build.mutation<
      Registration,
      { data: RegistrationRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'post',
        url: getAuthURL('register'),
        data,
        customOptions,
      }),
    }),
    login: build.mutation<
      Login,
      { data: LoginRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'post',
        url: getAuthURL('login'),
        data,
        customOptions,
      }),
      invalidatesTags: ['login'],
    }),
    changeUserPassword: build.mutation<
      string,
      { data: PatchedChangePasswordRequest; customOptions?: RequestOptionsType }
    >({
      query: ({ data, customOptions }) => ({
        method: 'patch',
        url: getAuthURL('user/change_password'),
        data,
        customOptions,
      }),
      invalidatesTags: ['patchUser'],
    }),
  }),
});
export const {
  useActivationQuery,
  useGetUserQuery,
  useLoginMutation,
  useRegisterMutation,
  useChangeUserPasswordMutation,
} = authApi;
