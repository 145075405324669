import { useEffect, useState } from 'react';
import { useGetHhUser } from '../../../../hooks/useGetHhUser';
import styles from './HhUserInfo.module.scss';
import { Statistics } from '../../Statistics/Statistics';
import { MenuStateEnum } from '../types';
import { useGetUserQuery } from 'services/RTKService/auth/endpoints/authApi';
import { ProgressBarColor } from 'Components/UI-kit/ProgressBar/ProgressBar';
// import { StatusBarList } from './StatusBarList/StatusBarList';

export const UserInfo = ({ menuState }: { menuState: MenuStateEnum }) => {
  const [hhUserId, changeHhUserId] = useState<number | undefined>();
  const { data: { granted_applies, applies } = {} } = useGetUserQuery();
  const { hhUserOptions, getHhUserById } = useGetHhUser();
  const hhUser = getHhUserById(hhUserId);

  useEffect(() => {
    if (!hhUserOptions.length) return;
    changeHhUserId(hhUserOptions[0]?.value);
  }, [hhUserOptions]);

  if (!hhUserId || !hhUser) return null;

  return (
    <div className={[styles.container, styles[menuState]].join(' ')}>
      {/* TODO: добавить статусбар когда будет готов бек */}
      {/* <StatusBarList menuState={menuState} /> */}
      <div className={styles.summary}>
        <Statistics
          className={styles.statistics}
          granted_applies={granted_applies}
          applies={applies}
          progressBarColor={ProgressBarColor.Yellow}
        />
      </div>
    </div>
  );
};
