// Импорт необходимых библиотек и компонентов
import { Outlet } from 'react-router-dom';
import { useGetMailingByIdQuery } from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { FormProvider, useForm } from 'react-hook-form';
import {
  RetrieveMailing,
  PatchedUpdateMailingRequest,
} from '../../../../ApiTypes';

import styles from './UpdateMailingFormProvider.module.scss';
import { useGetIdFromSearchParams } from '../hooks/useGetIdFromSearchParams';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { PatchedCreateMailingRequestSchema } from 'schemas/PatchedCreateMailingRequestSchema';
import { useGetLimitDesiredDailyPlan } from 'hooks/useGetLimitDesiredDailyPlan';
import { MAX_DAILY_PLAN_LIMIT } from 'config/const';

// Функция для вычисления скорректированного лимита откликов в день
const calculateAdjustedLimit = (
  dailyPlan: number | undefined,
  limit: number | undefined,
): number => {
  return (dailyPlan ?? 0) + (limit ?? MAX_DAILY_PLAN_LIMIT);
};

// Функция для создания динамической схемы валидации
const createDynamicSchema = (adjustedLimit: number) => {
  return z.intersection(
    PatchedCreateMailingRequestSchema,
    z.object({
      daily_plan: z
        .number()
        .max(
          adjustedLimit,
          `Суммарное кол-во откликов в день для одного Hh аккаунта не должно превышать ${MAX_DAILY_PLAN_LIMIT} откликов, для данной рассылки доступно ${adjustedLimit} откликов в день`,
        ),
    }),
  );
};

type PatchedUpdateMailingRequestFormValues = PatchedUpdateMailingRequest & {
  hh_user: number;
};
// Функция для получения значений формы из данных API
const getFormValues = (
  apiData: (RetrieveMailing & { restricted_employerId: number[] }) | undefined,
): PatchedUpdateMailingRequestFormValues | undefined => {
  if (apiData) {
    return {
      employment_type: apiData.employment_type,
      areas: apiData.areas,
      search_key: apiData.search_key,
      hh_user: apiData.hh_user,
      letter: apiData.letter,
      order: apiData.order,
      search_period: apiData.search_period,
      sleep_time: apiData.sleep_time,
      resume_hash: apiData.resume_hash,
      plan: apiData.plan,
      daily_plan: apiData.daily_plan,
      restricted_employers: apiData.restricted_employerId,
      experience: apiData.experience,
      schedule: apiData.schedule,
    };
  }
};

export const UpdateMailingFormProvider = () => {
  const editableMailingId = useGetIdFromSearchParams();

  const { data: mailingData } = useGetMailingByIdQuery(
    { id: editableMailingId as number },
    { skip: !editableMailingId },
  );

  // Получаем функцию для определения лимита откликов
  const { getLimitDesiredDailyPlan } = useGetLimitDesiredDailyPlan();

  // Инициализируем методы формы с валидацией
  const methods = useForm<PatchedUpdateMailingRequestFormValues>({
    resolver: (values, context, options) => {
      const { daily_plan } = mailingData || {};
      const { hh_user } = values;
      const limit = getLimitDesiredDailyPlan(hh_user);
      const adjustedLimit = calculateAdjustedLimit(daily_plan, limit);

      // Создаем динамическую схему на основе скорректированного лимита
      const dynamicSchema = createDynamicSchema(adjustedLimit);

      return zodResolver(dynamicSchema)(values, context, options);
    },
    values: getFormValues(mailingData),
  });

  return (
    <div className={styles.container}>
      <FormProvider {...methods}>
        <Outlet />
      </FormProvider>
    </div>
  );
};
