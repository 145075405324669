import { useState } from 'react';
import { DivProps } from 'Shared/interfaces';
import { Button } from '../Buttons/Button';
import { TextInput } from '../TextInput/TextInput';
import styles from './AccountCard.module.scss';
import {
  useDeleteHhUserMutation,
  usePatchUserMutation,
} from '../../../services/RTKService/hhUser/endpoints/hhUserApi';
import { useGetHhUser } from '../../../hooks/useGetHhUser';

interface Props extends DivProps {
  hh_id: number;
}

export const AccountCard = ({ hh_id, className, ...otherProps }: Props) => {
  const [cookies, setCookies] = useState<string>('');

  const { entities } = useGetHhUser();

  const hhUser = entities && entities[hh_id];

  const [patch] = usePatchUserMutation();
  const [deleteHHAccount] = useDeleteHhUserMutation();

  const onHandlePatchButton = async () => {
    if (!cookies.trim()) return;
    await patch({
      hhUserId: hh_id.toString(),
      data: { cookies },
    });
  };

  const onHandleDeleteButton = async () => {
    await deleteHHAccount({ id: hh_id });
  };

  if (!hhUser) return null;
  const { cookies_alive, email, resumes, applies_count } = hhUser;

  if (cookies_alive) {
    return (
      <div className={[styles.container, className].join(' ')} {...otherProps}>
        <div className={[styles.account, styles.topBlock].join(' ')}>
          <h4>Аккаунт</h4>
          <p>{email}</p>
        </div>
        <div className={styles.summaries}>
          <h4>Резюме</h4>
          <ul>
            {resumes.map(({ hash, name }) => (
              <li key={hash}>{name}</li>
            ))}
          </ul>
        </div>
        <div className={styles.statistics}>
          <h4>
            Количество совершенных откликов для Hh-аккаунта: {applies_count}
          </h4>
        </div>

        <Button
          key={'onHandleDeleteButton'}
          onClick={onHandleDeleteButton}
          className={styles.button}
        >
          Отвязать аккаунт
        </Button>
      </div>
    );
  } else {
    return (
      <div className={[styles.container, className].join(' ')} {...otherProps}>
        <div className={[styles.accountStatus, styles.topBlock].join(' ')}>
          Аккаунт не активен
        </div>
        <div className={styles.account}>
          <h4>Аккаунт</h4>
          <p>{email}</p>
        </div>
        {/* <Statistics
          className={styles.statistics}
          progressBarColor={ProgressBarColor.Yellow}
          dailyFeedbackRate={applies_count}
          totalFeedbackRate={200}
        /> */}
        <TextInput
          className={styles.input}
          placeholder='Скопированные куки'
          key={'Input'}
          value={cookies}
          changeValue={setCookies}
        />
        <div className={styles.buttons}>
          <Button
            key={'onHandlePatchButton'}
            onClick={onHandlePatchButton}
            className={styles.button}
            disabled={!cookies}
          >
            Привязать
          </Button>
          <Button
            key={'onHandleDeleteButton'}
            onClick={onHandleDeleteButton}
            className={styles.button}
          >
            Отвязать
          </Button>
        </div>
      </div>
    );
  }
};
