import { FC, memo } from 'react';
import { NavLink } from 'react-router-dom';
import { HiArrowSmLeft } from 'react-icons/hi';
import { ButtonWithLoader, ControlledInput, ErrorsMapper } from '../index';
import { Control, FieldErrors } from 'react-hook-form';
import {
  validEmailRules,
  validPasswordRules,
} from '../../Shared/hookForm/validationRules';
import { LoginRequest } from 'ApiTypes';

interface ISignLayoutProps {
  control: Control<LoginRequest>;
  heading: string;
  onSubmit: () => void;
  onLink: () => void;
  isLoading: boolean;
  errors: FieldErrors<LoginRequest>;
  linkName: string;
}

export const SignLayout: FC<ISignLayoutProps> = memo(
  ({
    control,
    heading = '',
    onSubmit,
    onLink,
    isLoading,
    linkName,
    errors,
  }) => {
    return (
      <div className='min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <h2 className='mt-6 text-center text-3xl font-extrabold text-gray-900'>
            {heading}
          </h2>
        </div>

        <div className='form-card mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={onSubmit}>
              <div className='flex justify-start items-center'>
                <NavLink
                  className='cursor-pointer flex flex-row text-sm font-medium text-gray-700'
                  to={'/'}
                >
                  <HiArrowSmLeft size='20' className='text-gray-700' />
                  На главную
                </NavLink>
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Почта
                </label>
                <ControlledInput<LoginRequest>
                  control={control}
                  name={'email'}
                  autoComplete={'email'}
                  rules={validEmailRules}
                />
              </div>
              <div>
                <label className='block text-sm font-medium text-gray-700'>
                  Пароль
                </label>
                <div className='mt-1'>
                  <ControlledInput<LoginRequest>
                    control={control}
                    name={'password'}
                    autoComplete={'password'}
                    type={'password'}
                    rules={validPasswordRules}
                  />
                </div>
              </div>
              <ErrorsMapper<LoginRequest> errors={errors} />
              <div className='flex justify-end'>
                <div
                  className='cursor-pointer mr-2 text-indigo-600 hover:underline text-indigo-700'
                  onClick={onLink}
                >
                  {linkName}
                </div>
              </div>
              <div>
                <ButtonWithLoader
                  autoFocus
                  disabled={isLoading}
                  type='submit'
                  isLoading={isLoading}
                >
                  Отправить
                </ButtonWithLoader>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  },
);
