import { useGetMailingListQuery } from 'services/RTKService/mailing/endpoints/mailingApi';

export const useGetLimitDesiredDailyPlan = () => {
  const { data } = useGetMailingListQuery();

  const getLimitDesiredDailyPlan = (hhAccountId: number | undefined) => {
    if (!hhAccountId) return;
    if (!data) return;

    const dailyPlanInFactForhhAccount = data.result
      .filter(({ hh_user }) => hh_user === hhAccountId)
      .reduce((acc, { daily_plan }) => {
        if (daily_plan) return acc + daily_plan;
        return acc;
      }, 0);

    if (!dailyPlanInFactForhhAccount) return 200;

    return 200 - dailyPlanInFactForhhAccount;
  };

  return { getLimitDesiredDailyPlan };
};
