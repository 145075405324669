import React from 'react';
import { Button, ButtonType } from '../../Buttons/Button';
import { ReactComponent as EditIcon } from 'assets/svg/editMailingCard.svg';
import { StatusEnum } from 'ApiTypes';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from 'config/navigation.data';
import { ToolTip, TooltipPosition } from 'Components/UI-kit/ToolTip';

interface EditMailingButtonProps {
  status: StatusEnum;
  id: number;
}

export const EditMailingButton: React.FC<EditMailingButtonProps> = ({
  status,
  id,
}) => {
  const navigate = useNavigate();

  const onHandleEdit = () => {
    navigate({
      pathname: NavigationPath.mailingEdit,
      search: `id=${id}`,
    });
  };

  switch (status) {
    case StatusEnum.RUNNING:
    case StatusEnum.PENDING:
    case StatusEnum.PLAN_COMPLETED:
    case StatusEnum.DAILY_PLAN_COMPLETED:
    case StatusEnum.OUT_OF_VACANCIES:
      return (
        <ToolTip
          className={'w-96'}
          content={
            'Необходимо остановить рассылку, прежде чем ее редактировать.'
          }
          position={TooltipPosition.Bottom}
          followMouse
        >
          <Button
            Icon={EditIcon}
            disabled
            interfaceType={ButtonType.Primary}
            onClick={onHandleEdit}
          >
            Изменить
          </Button>
        </ToolTip>
      );
    default:
      return (
        <Button
          Icon={EditIcon}
          interfaceType={ButtonType.Primary}
          onClick={onHandleEdit}
        >
          Изменить
        </Button>
      );
  }
};
