import styles from './MailingCardStatus.module.scss';
import cn from 'classnames';
import { ReactComponent as NotationSvg } from 'assets/svg/notation.svg';
import { useState } from 'react';
import { StatusEnum } from '../../../../ApiTypes';

// export enum MailingStatusVariant {
//   ACTIVE = 'ACTIVE',
//   COMPLETED = 'COMPLETED',
//   ON_PAUSE = 'ON_PAUSE',
//   READY_TO_GO = 'READY_TO_GO',
//   ERROR = 'ERROR',
// }

type Props = {
  statusVariant: StatusEnum;
  notation?: string;
};
export const MailingCardStatus = ({
  statusVariant = StatusEnum.ERROR,
  notation,
}: Props) => {
  const [notationView, setNotationView] = useState(false);

  const statuses = {
    [StatusEnum.RUNNING]: <p>Запущена</p>,
    [StatusEnum.STOPPED]: <p>Остановлена</p>,
    [StatusEnum.CREATED]: <p>Создана</p>,
    [StatusEnum.PENDING]: <p>Ожидает очереди</p>,
    [StatusEnum.PLAN_COMPLETED]: <p>Полностью выполнена</p>,
    [StatusEnum.DAILY_PLAN_COMPLETED]: <p>На сегодня выполнена</p>,
    [StatusEnum.HH_LIMIT_REACHED]: (
      <p>Достигнут лимит запросов, установленный hh.ru</p>
    ),
    [StatusEnum.OUT_OF_VACANCIES]: (
      <p>Закончились вакансии по данному запросу</p>
    ),
    // [StatusEnum.OUT_OF_GRANTED_APPLIES]: <p>Нет выданных откликов</p>,
    [StatusEnum.ERROR]: (
      <>
        <p>
          ОШИБКА
          <NotationSvg
            onMouseLeave={() => setNotationView(false)}
            onMouseEnter={() => setNotationView(true)}
            className={styles.notation_icon}
          />
        </p>
        {notation && (
          <div
            className={cn(styles.notation, notationView && styles.notationView)}
          >
            <p>{notation}</p>
          </div>
        )}
      </>
    ),
  };

  return (
    <div className={cn(styles.status, styles[statusVariant])}>
      {statuses[statusVariant]}
    </div>
  );
};
