import { BaseModal } from 'Components';
import { Button, ButtonType } from 'Components/UI-kit/Buttons/Button';
import { TELEGRAMM_SUPPORT } from 'config/const';
import { useEffect, useState } from 'react';
import { useGetUserQuery } from 'services/RTKService/auth/endpoints/authApi';
import { ReactComponent as TelegramIcon } from 'assets/svg/telegram.svg';

export const AvailableResponseLimitModal = () => {
  const [show, setShow] = useState(false);
  const { data: { granted_applies, applies } = {} } = useGetUserQuery();

  useEffect(() => {
    if (!granted_applies) return;
    if (!applies) return;
    if (applies >= granted_applies) setShow(true);
  }, [granted_applies, applies]);
  return (
    <BaseModal
      title='Лимит откликов'
      isOpen={show}
      onClose={() => setShow(false)}
    >
      <div className='flex flex-col gap-4'>
        <p>
          Вы достигли лимита доступных откликов.
          <br /> Чтобы продолжить рассылку, свяжитесь с нами.
        </p>

        <a
          className='flex'
          href={TELEGRAMM_SUPPORT}
          target={'_blank'}
          rel='noreferrer'
        >
          <Button
            className='flex-1'
            interfaceType={ButtonType.Primary}
            Icon={TelegramIcon}
          >
            Связаться
          </Button>
        </a>
      </div>
    </BaseModal>
  );
};
