// Generated by ts-to-zod
import { z } from 'zod';

import { AreasEnumSchemaGen } from './AreasEnumSchemaGen';
import { EmploymentTypeEnumSchemaGen } from './EmploymentTypeEnumSchemaGen';
import { ExperienceEnumSchemaGen } from './ExperienceEnumSchemaGen';
import { OrderEnumSchemaGen } from './OrderEnumSchemaGen';
import { ScheduleEnumSchemaGen } from './ScheduleEnumSchemaGen';
import { SearchPeriodEnumSchemaGen } from './SearchPeriodEnumSchemaGen';

export const PatchedUpdateMailingRequestSchemaGen = z.object({
  areas: z.array(AreasEnumSchemaGen).optional(),
  resume_hash: z.string().optional(),
  letter: z.string().optional().nullable(),
  order: OrderEnumSchemaGen.optional(),
  search_period: SearchPeriodEnumSchemaGen.optional(),
  search_key: z.string().optional(),
  employment_type: z.array(EmploymentTypeEnumSchemaGen).optional(),
  plan: z.number().optional(),
  daily_plan: z.number().optional(),
  sleep_time: z.number().optional().nullable(),
  restricted_employers: z.array(z.number()).optional(),
  experience: ExperienceEnumSchemaGen.optional(),
  schedule: z.array(ScheduleEnumSchemaGen).optional(),
});
