import React, { useRef } from 'react';
import styles from './BaseToolTip.module.scss';
import { pad } from 'lodash';

/**
 * Enum for tooltip positioning options.
 */
export enum TooltipPosition {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

/**
 * Props for the ToolTip component.
 * @property content - The content to display inside the tooltip, can be a string or React node.
 * @property children - The element that triggers the tooltip on hover.
 * @property followMouse - If true, the tooltip follows the mouse cursor.
 * @property position - The preferred position of the tooltip relative to the trigger element.
 * @property className - Additional class names to apply to the tooltip for custom styling.
 */
export interface ToolTipProps {
  content: string | React.ReactNode;
  children: React.ReactNode;
  followMouse?: boolean;
  position?: TooltipPosition;
  className?: string;
}

/**
 * A component that displays a tooltip with customizable content and positioning.
 * The tooltip can optionally follow the mouse cursor.
 *
 * @param {ToolTipProps} props - The props for the component.
 * @returns {JSX.Element} The rendered tooltip component.
 */
export const BaseToolTip: React.FC<ToolTipProps> = ({
  content,
  children,
  followMouse = false,
  position = TooltipPosition.Top,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  /**
   * Обновляет позицию тултипа на основе события мыши
   *
   * @param {React.MouseEvent<HTMLDivElement>} e - The mouse event triggering the position update.
   */
  const updateTooltipPosition = (e: React.MouseEvent<HTMLDivElement>) => {
    // Проверяем, следует ли тултипу следовать за курсором мыши и доступны ли ссылки на контейнер и тултип
    if (followMouse && containerRef.current && tooltipRef.current) {
      // Получаем размеры элемента тултипа
      const { clientWidth, clientHeight } = tooltipRef.current;
      // Получаем прямоугольник с размерами и позицией контейнера
      const rect = containerRef.current.getBoundingClientRect();
      // Вычисляем начальную позицию left для тултипа
      let left = e.clientX - rect.left + clientWidth / 2;
      // Вычисляем позицию top для тултипа
      const top = e.clientY - rect.top - clientHeight;
      // Получаем ширину экрана
      const screenWidth = window.innerWidth;

      // Получаем элемент с id 'menuLayout-container'
      const menuLayoutContainer = document.getElementById(
        'menuLayout-container',
      );

      let marginRight = 0;
      let padingLeft = 0;

      // Если элемент найден, получаем его вычисленные стили
      if (menuLayoutContainer) {
        const computedStyle = window.getComputedStyle(menuLayoutContainer);
        marginRight = parseFloat(computedStyle.marginRight);
        padingLeft = parseFloat(computedStyle.paddingLeft);
      }

      // Рассчитываем доступную ширину экрана с учетом отступов
      const prepareScreenWidth = screenWidth - marginRight - padingLeft;

      // Корректировка позиции тултипа, чтобы он не выходил за правую границу экрана
      if (e.clientX + clientWidth > prepareScreenWidth) {
        left = left - (e.clientX + clientWidth - prepareScreenWidth);
      }

      // Если тултип шире экрана, уменьшаем его ширину
      if (clientWidth > screenWidth) {
        tooltipRef.current.style.width = `${screenWidth * 0.7}px`;
      }

      // Устанавливаем рассчитанные позиции тултипа
      tooltipRef.current.style.left = `${left}px`;
      tooltipRef.current.style.top = `${top}px`;
    }
  };

  const classNames = [styles.tooltipContent, styles[position], className]
    .filter(Boolean)
    .join(' ');

  return (
    <div
      ref={containerRef}
      onMouseMove={updateTooltipPosition}
      className={styles.tooltipContainer}
    >
      {children}
      <div ref={tooltipRef} className={classNames}>
        {typeof content === 'string' ? (
          <p className={styles.tooltipText}>{content}</p>
        ) : (
          content
        )}
      </div>
    </div>
  );
};
