import {
  EmploymentTypeEnum,
  ExperienceEnum,
  OrderEnum,
  ScheduleEnum,
  SearchPeriodEnum,
} from '../../../../ApiTypes';

export const orderOptions: { value: OrderEnum; label: string }[] = [
  { value: OrderEnum.RELEVANCE, label: 'По соответствию' },
  { value: OrderEnum.PUBLICATION_TIME, label: 'По дате изменения' },
  { value: OrderEnum.SALARY_DESC, label: 'По убыванию зарплат' },
  { value: OrderEnum.SALARY_ASC, label: 'По возрастанию зарплаты' },
];

export const searchPeriodOptions: { value: SearchPeriodEnum; label: string }[] =
  [
    { value: SearchPeriodEnum._0, label: 'За всё время' },
    { value: SearchPeriodEnum._30, label: 'За месяц' },
    { value: SearchPeriodEnum._7, label: 'За неделю' },
    { value: SearchPeriodEnum._3, label: 'За последние три дня' },
    { value: SearchPeriodEnum._1, label: 'За сутки' },
  ];

export const employmentTypeOptions: {
  value: EmploymentTypeEnum;
  label: string;
}[] = [
  { value: EmploymentTypeEnum.FULL, label: 'Полная занятость' },
  { value: EmploymentTypeEnum.PART, label: 'Частичная занятость' },
  { value: EmploymentTypeEnum.PROJECT, label: 'Проектная деятельность' },
  { value: EmploymentTypeEnum.VOLUNTEER, label: 'Волонтерство' },
  { value: EmploymentTypeEnum.PROBATION, label: 'Стажировка' },
];

export const scheduleOptions: { value: ScheduleEnum; label: string }[] = [
  { value: ScheduleEnum.FULL_DAY, label: 'Полный день' },
  { value: ScheduleEnum.FLY_IN_FLY_OUT, label: 'Вахтовый метод' },
  { value: ScheduleEnum.SHIFT, label: 'Сменный график' },
  { value: ScheduleEnum.FLEXIBLE, label: 'Гибкий график' },
  { value: ScheduleEnum.REMOTE, label: 'Удаленная работа' },
];

export const experienceOptions: { value: ExperienceEnum; label: string }[] = [
  { value: ExperienceEnum.DOES_NOT_MATTER, label: 'Не имеет значения' },
  { value: ExperienceEnum.NO_EXPERIENCE, label: 'Нет опыта' },
  { value: ExperienceEnum.BETWEEN1AND3, label: 'От 1 до 3х лет' },
  { value: ExperienceEnum.BETWEEN3AND6, label: 'От 3х до 6-ти лет' },
  { value: ExperienceEnum.MORE_THAN6, label: 'Более 6-ти лет' },
];
