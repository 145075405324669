import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../../../services/RTKService/auth/endpoints/authApi';
import { addServerErrors } from '../../../Shared/hookForm/addServerErrors';
import { Button, ButtonType } from '../Buttons/Button';
import { HookFormTextInput } from '../TextInput/HookFormTextInput';
import styles from './SignUpCard.module.scss';
import { RegistrationRequest } from '../../../ApiTypes';
import { NavigationPath } from '../../../config/navigation.data';
import { ErrorsMapper } from 'Components/UI-kit/ServerErrors/ErrorsMapper';
import { KeyboardEventHandler } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { RegistrationRequestSchema } from 'schemas/RegistrationRequestSchema';

interface ISignUpForm extends RegistrationRequest {
  confirmPassword: string;
}

interface Props {
  onClickSignIn: () => void;
}

export const SignUpCard = ({ onClickSignIn }: Props) => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm<ISignUpForm>({
    resolver: zodResolver(RegistrationRequestSchema),
  });

  const [register] = useRegisterMutation();
  const onSubmit = handleSubmit(async ({ email, password }) => {
    const res = await register({
      data: { email, password },
      customOptions: { showErrorToast: false },
    });
    if ('data' in res) {
      reset();
      navigate(
        { pathname: NavigationPath.confirm_email },
        { state: { email } },
      );
    }
    if ('error' in res) {
      addServerErrors(res, setError);
    }
  });

  const onHandleKeyDown: KeyboardEventHandler<HTMLDivElement> = (e) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <div className={styles.registrationCard} onKeyDown={onHandleKeyDown}>
      <h2>Регистрация</h2>
      <div className={styles.inputsHolder}>
        <HookFormTextInput<ISignUpForm>
          control={control}
          name={'email'}
          label={'Почта'}
          isHiddenLabel
          placeholder={'Почта'}
          autoComplete={'email'}
          isShowError={false}
        />
        <HookFormTextInput<ISignUpForm>
          control={control}
          name={'password'}
          label={'Пароль'}
          isHiddenLabel
          placeholder={'Пароль'}
          autoComplete={'password'}
          type={'password'}
          isShowError={false}
        />
        <HookFormTextInput<ISignUpForm>
          control={control}
          name={'confirmPassword'}
          label={'Повторите пароль'}
          isHiddenLabel
          placeholder={'Повторите пароль'}
          autoComplete={'password'}
          type={'password'}
          isShowError={false}
        />
      </div>
      <ErrorsMapper<ISignUpForm>
        errors={errors}
        allErrors
        renderFunc={(mappedErrors) => (
          <div className={styles.errorContainer}>
            {mappedErrors.map((err, index) => (
              <span key={index} className={styles.errorMessage}>
                {err}
              </span>
            ))}
          </div>
        )}
      />
      <div className={styles.buttonsHolder}>
        <Button onClick={onSubmit} interfaceType={ButtonType.Primary}>
          Продолжить
        </Button>
        <Button onClick={onClickSignIn} interfaceType={ButtonType.Secondary}>
          Войти
        </Button>
      </div>
    </div>
  );
};
