import { CreateMailingRequest } from '../../../../ApiTypes';
import { useFormContext } from 'react-hook-form';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import styles from './EditMailingStep1.module.scss';
import { useNavigate } from 'react-router-dom';
import { NavigationPath } from '../../../../config/navigation.data';
import { CreateSelectors } from '../Selectors/CreateSelectors';

export const EditMailingStep1 = () => {
  const { reset, trigger } = useFormContext<CreateMailingRequest>();

  const navigate = useNavigate();

  const onHandleReset = () => {
    reset();
  };

  const onHandleCreateAndNext = async () => {
    const triggerResult = await trigger([
      'areas',
      'resume_hash',
      'letter',
      'order',
      'search_period',
      'search_key',
      'employment_type',
      'hh_user',
      'restricted_employers',
      'experience',
      'schedule',
    ]);

    if (!triggerResult) return;
    navigate({
      pathname: NavigationPath.mailingCreateStep2,
    });
  };

  return (
    <div className={styles.container}>
      <CreateSelectors />

      <div className={styles.buttons}>
        <Button onClick={onHandleReset} interfaceType={ButtonType.Secondary}>
          Сбросить всё
        </Button>
        <Button
          onClick={onHandleCreateAndNext}
          interfaceType={ButtonType.Primary}
        >
          Продолжить
        </Button>
      </div>
    </div>
  );
};
