import { Outlet } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CreateMailingRequest,
  EmploymentTypeEnum,
  ExperienceEnum,
  OrderEnum,
  ScheduleEnum,
  SearchPeriodEnum,
} from '../../../../ApiTypes';

import styles from './CreateMailingFormProvider.module.scss';
import { useEffect } from 'react';
import { CreateMailingRequestSchema } from '../../../../schemas/CreateMailingRequestSchema';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useGetHhUser } from '../../../../hooks/useGetHhUser';
import { LoadFormModal } from './LoadFormModal/LoadFormModal';
import { usePersistOnUnmountAndUnload } from '../hooks/usePersistOnUnmountAndUnload';
import { FORM_VALUES } from '../constants';
import { useGetLimitDesiredDailyPlan } from 'hooks/useGetLimitDesiredDailyPlan';
import { MAX_DAILY_PLAN_LIMIT } from 'config/const';

// Функция для создания динамической схемы валидации
// Использует переданный лимит или значение по умолчанию
const createDynamicSchema = (limit: number | undefined) => {
  return z.intersection(
    CreateMailingRequestSchema,
    z.object({
      daily_plan: z
        .number()
        .max(
          limit || MAX_DAILY_PLAN_LIMIT,
          `Суммарное кол-во откликов в день для одного Hh аккаунта не должно превышать ${MAX_DAILY_PLAN_LIMIT} откликов, для данной рассылки доступно ${
            limit || MAX_DAILY_PLAN_LIMIT
          } откликов в день`,
        ),
    }),
  );
};

// Функция для получения значений формы по умолчанию
// Использует переданные параметры или значения по умолчанию
const getDefaultFormValues = (
  resumeOptions: { value: string; label: string }[],
  hhUserId: number | undefined,
  daily_plan = MAX_DAILY_PLAN_LIMIT,
): CreateMailingRequest => {
  return {
    employment_type: [EmploymentTypeEnum.FULL],
    areas: [],
    search_key: resumeOptions[0]?.label,
    hh_user: hhUserId as number,
    letter: '',
    order: OrderEnum.RELEVANCE,
    search_period: SearchPeriodEnum._0,
    sleep_time: undefined,
    resume_hash: resumeOptions[0]?.value,
    plan: 1000,
    daily_plan,
    restricted_employers: [],
    experience: ExperienceEnum.DOES_NOT_MATTER,
    schedule: [ScheduleEnum.FULL_DAY],
  };
};

export const CreateMailingFormProvider = () => {
  const { ids, entities, getResumeOptionsById } = useGetHhUser();

  const defaultHhUserId = ids && ids[0];

  // Максимальное количество откликов в день
  const { getLimitDesiredDailyPlan } = useGetLimitDesiredDailyPlan();

  const methods = useForm<CreateMailingRequest>({
    // Функция для валидации формы
    // Использует динамическую схему на основе лимита
    resolver: (values, context, options) => {
      const { hh_user } = values;
      const limit = getLimitDesiredDailyPlan(hh_user);

      // Создаем динамическую схему на основе лимита
      const dynamicSchema = createDynamicSchema(limit);

      // Используем динамическую схему для валидации
      return zodResolver(dynamicSchema)(values, context, options);
    },
    // Значения формы по умолчанию
    values: getDefaultFormValues(
      getResumeOptionsById(defaultHhUserId),
      defaultHhUserId,
      getLimitDesiredDailyPlan(defaultHhUserId),
    ),
  });

  const { watch, setValue, trigger, getValues } = methods;

  // Сохраняем значения формы при размонтировании компонента
  usePersistOnUnmountAndUnload({ key: FORM_VALUES, getValues });

  // Следим за изменением hh_user в форме
  useEffect(() => {
    //следим за hh_user в форме и меняем resume_hash и search_key в случае изменения
    const select_Hh_userId = watch('hh_user');
    if (!select_Hh_userId) return;
    const selectHh_user = entities && entities[select_Hh_userId];
    if (!selectHh_user) return;
    const { resumes } = selectHh_user;
    setValue('search_key', resumes[0]?.name);
    setValue('resume_hash', resumes[0]?.hash);
  }, [watch('hh_user')]);

  // Следим за изменением resume_hash в форме
  useEffect(() => {
    //следим за resume_hash в форме и меняем desired_vacancy_search_key в случае изменения
    const select_resume_hash = watch('resume_hash');
    const select_hh_userId = watch('hh_user');
    if (!select_resume_hash) return;
    const options = getResumeOptionsById(select_hh_userId);
    const selectResumeOption = options.find(
      ({ value }) => value === select_resume_hash,
    );
    if (!selectResumeOption) return;
    const desired_vacancy_search_key = selectResumeOption.label;
    setValue('search_key', desired_vacancy_search_key);
    trigger('search_key');
  }, [watch('resume_hash')]);

  return (
    <div className={styles.container}>
      <FormProvider {...methods}>
        <Outlet />
        <LoadFormModal />
      </FormProvider>
    </div>
  );
};
