import { SelectorType } from 'Components/UI-kit/Selector/types';
import { WithHookFormControlSelector } from 'Components/UI-kit/Selector/WithHookFormControlSelector';
import { CreateMailingRequest, PatchedUpdateMailingRequest } from 'ApiTypes';
import { useGetHhUser } from 'hooks/useGetHhUser';
import { useFormContext } from 'react-hook-form';

export const ResumesContainer = () => {
  const { control, watch } = useFormContext<
    CreateMailingRequest | PatchedUpdateMailingRequest
  >();
  const { getResumeOptionsById } = useGetHhUser();
  return (
    <WithHookFormControlSelector
      name={'resume_hash'}
      control={control}
      placeholder={'Резюме для откликов'}
      options={getResumeOptionsById(watch('hh_user'))}
      type={SelectorType.Form}
    />
  );
};
