import { useLayoutEffect, useState } from 'react';
import styles from './MenuBar.module.scss';

import { DivProps } from '../../../Shared/interfaces';
import { NavigationItem } from '../../../config/navigation.data';
import { useMatchScreenWidth } from '../../../hooks/useMatchScreenWidth';
import {
  ThemesSwitch,
  ThemesSwitchVariant,
} from '../ThemesSwitch/ThemesSwitch';
import { Content } from './Content/Content';
import { Header } from './Header/Header';
import { Navigation } from './Navigation/Navigation';
import { Support } from './Support/Support';

import { MenuStateEnum } from './types';
import { Container } from './Container/Container';
import { UserInfo } from './UserInfo/UserInfo';

interface Props extends DivProps {
  menuItems: NavigationItem[];
  contentClassName?: string;
}

export const MenuBar = ({ menuItems = [], className, ...rest }: Props) => {
  const screen = useMatchScreenWidth();

  const [menuState, setMenuState] = useState<MenuStateEnum>(
    MenuStateEnum.expanded,
  );

  useLayoutEffect(() => {
    if (screen.xxl) {
      setMenuState(MenuStateEnum.expanded);
      return;
    }
    if (screen.sm) {
      setMenuState(MenuStateEnum.horizonCollapse);
      return;
    }
    if (screen.all) {
      setMenuState(MenuStateEnum.verticalCollapse);
      return;
    }
  }, [screen]);

  const onHandleCollapse = () => {
    const onSetMenuState = (newState: MenuStateEnum) => {
      setMenuState((prev) =>
        prev === 'expanded' ? newState : MenuStateEnum.expanded,
      );
      return;
    };
    if (screen.sm) {
      onSetMenuState(MenuStateEnum.horizonCollapse);
      return;
    }
    if (screen.all) {
      onSetMenuState(MenuStateEnum.verticalCollapse);
      return;
    }
  };

  return (
    <Container
      menuState={menuState}
      className={[styles.menuBarConstants, className].join(' ')}
      {...rest}
    >
      <Header onHandleButton={onHandleCollapse} variant={menuState} />
      <Content variant={menuState}>
        <Navigation menuState={menuState} menuItems={menuItems} />
        <UserInfo menuState={menuState} />
        <Support menuState={menuState} />

        <ThemesSwitch
          className={styles.themeSwitcher}
          variant={
            menuState === 'horizonCollapse'
              ? ThemesSwitchVariant.Vertical
              : ThemesSwitchVariant.Squarer
          }
        />
      </Content>
    </Container>
  );
};
