import { z } from 'zod';
import { LoginRequestSchemaGen } from './gen/LoginRequestSchemaGen';

export const LoginRequestSchema = z.intersection(
  z.object({
    email: z.string().email({ message: 'Пожалуйста введите правильный адрес' }),
    password: z
      .string()
      .min(8, { message: 'Пароль должен быть не менее 8 символов' })
      .max(128, { message: 'Пароль должен быть не более 128 символов' }),
  }),
  LoginRequestSchemaGen,
);
