import { pokemonApi } from '../../RTKService';

import { getHhUserURL } from '../../../getURLHelpers/getURLHelpers';
import { RequestOptionsType } from '../../../request';
import { HhUser, HhUserRequest } from '../../../../ApiTypes';
import { createEntityAdapter, EntityState } from '@reduxjs/toolkit';
import { WSInstance } from 'services/instance';

export const hhUserApi = pokemonApi.injectEndpoints({
  endpoints: (build) => ({
    getHhUserList: build.query<
      HhUserListResponse,
      {
        customOptions?: RequestOptionsType;
        params?: { hh_data: boolean };
      } | void
    >({
      query: (args) => ({
        url: getHhUserURL(),
        customOptions: args?.customOptions,
        params: args?.params ?? { hh_data: true },
      }),
      providesTags: ['postHhUser', 'patchHhUser', 'deleteHhUser'],
      transformResponse: transformResponseHhUser,
      async onCacheEntryAdded(
        _,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // create a websocket connection when the cache subscription starts
        let ws;
        try {
          ws = new WSInstance(getHhUserURL());
          // wait for the initial query to resolve before proceeding
          await cacheDataLoaded;

          // when data is received from the socket connection to the server,
          // if it is a message and for the appropriate channel,
          // update our query result with the received message
          const listener = (event: MessageEvent) => {
            updateCachedData(() =>
              transformResponseHhUser(JSON.parse(event.data)),
            );
          };

          ws.addEventListener('message', listener);
        } catch (error) {
          console.error(error);
          // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`,
          // in which case `cacheDataLoaded` will throw
        }
        // cacheEntryRemoved will resolve when the cache subscription is no longer active
        await cacheEntryRemoved;
        // perform cleanup steps once the `cacheEntryRemoved` promise resolves
        ws && ws.close();
      },
    }),
    getHhUserById: build.query<
      {
        result: HhUser;
        resumeOptions: { value: string; label: string }[];
      },
      {
        id: number;
        customOptions?: RequestOptionsType;
        params?: { hh_data: boolean };
      }
    >({
      query: (args) => ({
        url: getHhUserURL(`/${args.id}/`),
        customOptions: args.customOptions,
        params: args.params ?? { hh_data: true },
      }),
      providesTags: ['postHhUser', 'patchHhUser', 'deleteHhUser'],
      transformResponse: ({
        email,
        resumes,
        applies_count,
        id,
        cookies_alive,
        ...rest
      }: HhUser) => ({
        result: { email, resumes, applies_count, id, cookies_alive, ...rest },
        resumeOptions: resumes.map(({ hash, name }) => ({
          value: hash,
          label: name,
        })),
      }),
    }),

    deleteHhUser: build.mutation<
      HhUser,
      { id: number; customOptions?: RequestOptionsType }
    >({
      query: ({ id, customOptions }) => ({
        url: getHhUserURL(`/${id}/`),
        method: 'delete',
        customOptions,
      }),
      invalidatesTags: ['deleteHhUser'],
    }),

    patchUser: build.mutation<
      HhUser,
      {
        data: { cookies: string };
        hhUserId: string;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ data: { cookies }, hhUserId, customOptions }) => ({
        url: getHhUserURL(`/${hhUserId}/`),
        method: 'patch',
        data: { cookies: JSON.parse(cookies) },
        customOptions,
      }),
      invalidatesTags: ['patchHhUser'],
    }),
    postHhUser: build.mutation<
      HhUser,
      {
        data: HhUserRequest;
        customOptions?: RequestOptionsType;
      }
    >({
      query: ({ data, customOptions }) => ({
        url: getHhUserURL(),
        method: 'post',
        data,
        customOptions,
      }),
      invalidatesTags: ['postHhUser'],
    }),
  }),
});

type HhUserWithResumeOptions = HhUser & {
  resumeOptions: { label: string; value: string }[];
};

type HhUserListResponse = {
  normalizeHhUserList: EntityState<HhUserWithResumeOptions>;
  result: HhUser[];
  hhUserOptions: { label: string; value: number }[];
};

const hhUserListAdapter = createEntityAdapter<HhUserWithResumeOptions>();

function transformResponseHhUser(hhUsers: HhUser[]): HhUserListResponse {
  const hhUsersList: HhUserWithResumeOptions[] = hhUsers.map(
    ({ resumes = [], ...rest }) => ({
      ...rest,
      resumes,
      resumeOptions: resumes.map(({ name, hash }) => ({
        label: name,
        value: hash,
      })),
    }),
  );

  return {
    normalizeHhUserList: hhUserListAdapter.addMany(
      hhUserListAdapter.getInitialState(),
      hhUsersList,
    ),
    hhUserOptions: hhUsers.reduce(
      (
        acc: { label: string; value: number }[],
        { id, email, cookies_alive },
      ) => {
        if (!cookies_alive) return acc;
        acc.push({
          label: email as string,
          value: id,
        });
        return acc;
      },
      [],
    ),
    result: hhUsers,
  };
}

export const {
  useGetHhUserListQuery,
  useDeleteHhUserMutation,
  usePatchUserMutation,
  usePostHhUserMutation,
} = hhUserApi;
