import { DivProps } from 'Shared/interfaces';
import { ProgressBar, ProgressBarColor } from '../ProgressBar/ProgressBar';
import { ResponseCounter } from '../ResponseCounter/ResponseCounter';
import styles from './Statistics.module.scss';
interface Props extends DivProps {
  granted_applies?: number;
  applies?: number;
  progressBarColor?: ProgressBarColor;
}

export const Statistics = ({
  granted_applies,
  applies,
  progressBarColor,
  className,
  ...otherProps
}: Props) => {
  return (
    <div className={[styles.statistics, className].join(' ')} {...otherProps}>
      <div className={styles.limit}>
        <h4> Лимит доступных откликов</h4>
        <ProgressBar
          count={applies}
          totalCount={granted_applies}
          color={progressBarColor}
        />
      </div>
      <div className={styles.responseCounter}>
        <ResponseCounter count={applies} totalCount={granted_applies} />
        <p>откликов</p>
      </div>
    </div>
  );
};
