export const API_ADDRESS = process.env.REACT_APP_API_ADDRESS;
export const WS_ADDRESS = process.env.REACT_APP_WS_ADDRESS;
export const TELEGRAMM_SUPPORT = 'https://t.me/ilyacobra';
export const EMAIL_SUPPORT = 'hhmailer.team@gmail.com';
export const MAX_DAILY_PLAN_LIMIT = 200;

export const APP_REFRESH_RATE =
  Number(process.env.REACT_APP_REFRESH_RATE) * 1000;
export const APP_MAX_REFRESH_TIME =
  Number(process.env.REACT_APP_REFRESH_MAX_TIME) * 60 * 1000;
