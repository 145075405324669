import { Outlet, useNavigate } from 'react-router-dom';
import {
  mailingStepsNavigationItems,
  NavigationPath,
} from '../../../../config/navigation.data';
import { MailingSteps } from '../../../../Components/UI-kit/MallingSteps/MailingSteps';
import styles from './CreateMailing.module.scss';

export const CreateMailing = () => {
  const navigate = useNavigate();

  const onHandleStep = async (path: NavigationPath) => {
    switch (path) {
      case NavigationPath.mailingCreateStep1:
        navigate({
          pathname: NavigationPath.mailingCreateStep1,
        });
        break;
      case NavigationPath.mailingCreateStep2:
        navigate({
          pathname: NavigationPath.mailingCreateStep2,
        });
        break;
      case NavigationPath.mailingCreateStep3:
        navigate({
          pathname: NavigationPath.mailingCreateStep3,
        });
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.mailingStepsContainer}>
        <MailingSteps
          className={styles.mailingSteps}
          onHandleStep={onHandleStep}
          navigationItems={mailingStepsNavigationItems}
        />
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
