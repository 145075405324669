import styles from './EditMailingStep3.module.scss';
import {
  Button,
  ButtonType,
} from '../../../../Components/UI-kit/Buttons/Button';
import { NavigationPath } from '../../../../config/navigation.data';
import { useNavigate } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { CreateMailingRequest } from '../../../../ApiTypes';
import {
  useCreateMailingMutation,
  useStartMailingMutation,
} from '../../../../services/RTKService/mailing/endpoints/mailingApi';
import { DesiredAppliesInputs } from '../DesiredAppliesInputs/DesiredAppliesInputs';
import { removeStorageItem } from 'services/localStorageService/localStorageService';
import { useEffect } from 'react';
import { FORM_VALUES } from '../constants';

export const EditMailingStep3 = () => {
  const { handleSubmit, reset } = useFormContext<CreateMailingRequest>();
  const [create, { isSuccess: isSuccessCreate }] = useCreateMailingMutation();
  const [start, { isSuccess: isSuccessStart }] = useStartMailingMutation();

  const navigate = useNavigate();

  // при успешном создании форма из localStorage должна удалиться после выхода с данной страницы
  useEffect(() => {
    if (!isSuccessCreate || isSuccessStart) return;
    return () => {
      removeStorageItem(FORM_VALUES);
    };
  }, [isSuccessCreate || isSuccessStart]);

  const onHandleBack = () => {
    navigate({
      pathname: NavigationPath.mailingCreateStep1,
    });
  };

  const onHandleSave = handleSubmit(async (data) => {
    const response = await create({
      data,
      customOptions: {
        showSuccessToast: true,
        successMessage: 'Создано',
      },
    });
    if ('data' in response) {
      navigate({ pathname: NavigationPath.mailing });
      reset();
    }
    if ('error' in response) {
      console.log(response);
    }
  });
  const onHandleSaveAndStart = handleSubmit(async (data) => {
    const createResponse = await create({
      data,
    });
    if ('data' in createResponse) {
      const startResponse = await start({
        id: createResponse.data.id,
        customOptions: {
          showSuccessToast: true,
          successMessage: 'Создано и запущено',
        },
      });
      if ('data' in startResponse) {
        navigate({ pathname: NavigationPath.mailing });
        reset();
      }
      if ('error' in startResponse) {
        console.log(createResponse);
      }
    }
    if ('error' in createResponse) {
      console.log(createResponse);
    }
  });
  return (
    <div className={styles.container}>
      <DesiredAppliesInputs />
      <div className={styles.buttons}>
        <Button onClick={onHandleBack} interfaceType={ButtonType.Secondary}>
          Назад
        </Button>
        <Button onClick={onHandleSave} interfaceType={ButtonType.Primary}>
          Сохранить
        </Button>
        <Button
          className={'whitespace-nowrap'}
          onClick={onHandleSaveAndStart}
          interfaceType={ButtonType.Primary}
        >
          Сохранить и запустить
        </Button>
      </div>
    </div>
  );
};
