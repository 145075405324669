import { setStorageItem } from '../../../services/localStorageService/localStorageService';
import { Login } from '../../../ApiTypes';

export const setUserInLocalStorage = (data: Login) =>
  new Promise((resolve, reject) => {
    try {
      setStorageItem('isAuth', true);
      setStorageItem('token', data.token);
      setStorageItem('email', data.email);
      resolve(true);
    } catch (e) {
      reject(false);
    }
  });
