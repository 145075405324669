// Generated by ts-to-zod
import { z } from 'zod';
import { RegistrationRequestSchemaGen } from './gen/RegistrationRequestSchemaGen';

export const RegistrationRequestSchema = z.intersection(
  z
    .object({
      email: z
        .string()
        .email({ message: 'Пожалуйста введите правильный адрес' }),
      password: z
        .string()
        .min(8, { message: 'Пароль должен быть не менее 8 символов' })
        .max(128, { message: 'Пароль должен быть не более 128 символов' }),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: 'Пароли не совпадают',
      path: ['confirmPassword'],
    }),
  RegistrationRequestSchemaGen,
);
